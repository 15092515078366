
.bg {
	width: 100%;
	margin: auto;
	height: 100vh;
	max-height: 600px;
	overflow: hidden;
	position: absolute;
	top: 0;

	.aur_cont {
		margin: -65px auto 0;
		display: flex;
		justify-content: space-evenly;
		height: auto;
        max-width: 1440px;
		.aur {
			transform: skew(-0.06turn, 18deg);
			display: block;
			width: 0;
			min-height: 200px;
			float: left;
			margin-left: 40px;
			border-radius: 5% 52% 30px 20px;
			opacity: 0.8;

            &.aur_1 {
                box-shadow: #4b9daf 0px 0px 100px 20px;
                margin-top: 62px;
                animation: topup 4474ms infinite linear;
            }
            &.aur_2 {
                box-shadow: #4b8a48 0px 0px 100px 20px;
                margin-top: 61px;
                animation: topup 5678ms infinite linear;
            }
            &.aur_3 {
                box-shadow: #4bcfc1 0px 0px 100px 20px;
                margin-top: -16px;
                animation: topup 5527ms infinite linear;
            }
            &.aur_4 {
                box-shadow: #4b8ccb 0px 0px 100px 20px;
                margin-top: 98px;
                animation: topup 5667ms infinite linear;
            }
            &.aur_5 {
                box-shadow: #4bffd4 0px 0px 100px 20px;
                margin-top: 84px;
                animation: topup 6413ms infinite linear;
            }
            &.aur_6 {
                box-shadow: #4b6d63 0px 0px 100px 20px;
                margin-top: 46px;
                animation: topup 3258ms infinite linear;
            }
            &.aur_7 {
                box-shadow: #4bff59 0px 0px 100px 20px;
                margin-top: -21px;
                animation: topup 4990ms infinite linear;
            }
            &.aur_8 {
                box-shadow: #4bff8f 0px 0px 100px 20px;
                margin-top: -22px;
                animation: topup 3977ms infinite linear;
            }
            &.aur_9 {
                box-shadow: #4bcabe 0px 0px 100px 20px;
                margin-top: 40px;
                animation: topup 5328ms infinite linear;
            }
            &.aur_10 {
                box-shadow: #4bb7e8 0px 0px 100px 20px;
                margin-top: 13px;
                animation: topup 7865ms infinite linear;
            }
            &.aur_11 {
                box-shadow: #4b76ff 0px 0px 100px 20px;
                margin-top: 57px;
                animation: topup 4700ms infinite linear;
            }
            &.aur_12 {
                box-shadow: #4bffff 0px 0px 100px 20px;
                margin-top: 48px;
                animation: topup 3096ms infinite linear;
            }
            &.aur_13 {
                box-shadow: #4ba04f 0px 0px 100px 20px;
                margin-top: 90px;
                animation: topup 7410ms infinite linear;
            }
            &.aur_14 {
                box-shadow: #4bc1df 0px 0px 100px 20px;
                margin-top: 90px;
                animation: topup 3806ms infinite linear;
            }
            &.aur_15 {
                box-shadow: #4bff43 0px 0px 100px 20px;
                margin-top: 79px;
                animation: topup 5203ms infinite linear;
            }
            &.aur_16 {
                box-shadow: #4b8941 0px 0px 100px 20px;
                margin-top: 6px;
                animation: topup 4291ms infinite linear;
            }
            &.aur_17 {
                box-shadow: #4bffba 0px 0px 100px 20px;
                margin-top: 11px;
                animation: topup 4189ms infinite linear;
            }
            &.aur_18 {
                box-shadow: #4b91a0 0px 0px 100px 20px;
                margin-top: 3px;
                animation: topup 4505ms infinite linear;
            }
            &.aur_19 {
                box-shadow: #4bbfff 0px 0px 100px 20px;
                margin-top: 19px;
                animation: topup 4422ms infinite linear;
            }
            &.aur_20 {
                box-shadow: #4b71ff 0px 0px 100px 20px;
                margin-top: 89px;
                animation: topup 5803ms infinite linear;
            }
            &.aur_21 {
                box-shadow: #4bffff 0px 0px 100px 20px;
                margin-top: -22px;
                animation: topup 5319ms infinite linear;
            }
            &.aur_22 {
                box-shadow: #4bff77 0px 0px 100px 20px;
                margin-top: -34px;
                animation: topup 3916ms infinite linear;
            }
            &.aur_23 {
                box-shadow: #4bffc5 0px 0px 100px 20px;
                margin-top: 1px;
                animation: topup 5464ms infinite linear;
            }
            &.aur_24 {
                box-shadow: #4bc1ff 0px 0px 100px 20px;
                margin-top: 51px;
                animation: topup 7334ms infinite linear;
            }
		}
    }
}

@keyframes topup{
    0%,100% {
        transform: translatey(30px) rotate(20deg);
    }
    25% {
        transform: translatey(100px) rotate(10deg) translatex(30px);
    }
    50% {
        transform: translatey(200px) rotate(-10deg);
    }
    75% {
        transform: translatey(100px) rotate(10deg) translatex(-30px);
    }
}