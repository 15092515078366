@use './scss/mediaQueries.scss';

body { background: #0f0f0f; }
// html{
//     scroll-behavior: smooth;
// }

// :target:before {
//     content: "";
//     display: block;
//     height: 50px;
//     margin: -50px 0 0;
// }
