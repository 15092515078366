#contact {
    background: #191919;
    padding-top: 96px;
    padding-bottom: 102px;
    color: #636363;

    .iframeContainer{
        position: relative;
        display: flex;
        flex-direction: column;

        justify-content: center;
        align-items: center;

        .hoverMsg{
            opacity: 0;
            z-index: 2;
            position: absolute;
            pointer-events: none;
            border-radius: 10px;
            padding: 20px;
            width: 500px;
            max-width: 92vw;
            color: white;
            background-color: #444;
            will-change: opacity, transform;
            text-align: center;

            p{margin-bottom: 0;}
            hr{margin-bottom: 15px;}

            @media only screen and (max-width: 500px) {
                font-size: 11px;
            }
        } 
    }

    iframe {
        box-sizing: border-box;
        filter: invert(0.9) saturate(0.5) hue-rotate(145deg);
        display: block;
        margin: 0 auto 30px;
        width: 92vw;
        max-width: 1024px;

        &.showMsg + .hoverMsg{
            animation: showMessage 5s;
        }
    }

    @keyframes showMessage {
        0%{
            opacity: 0;
            transform: translateY(30px);}

        20%{
            opacity: .9;
            transform: translateY(0);}

        80%{
            opacity: .9;
            transform: translateY(0);}


        100%{
            opacity: 0;
            transform: translateY(30px);}
    }

    .section-head {
        margin-bottom: 42px;
    }


    h1 {
        font: 18px/24px 'opensans-bold', sans-serif;
        text-transform: uppercase;
        letter-spacing: 3px;
        color: #EBEEEE;
        margin-bottom: 6px;

        span {
            display: none;
        }

        &:before {
            font-family: 'FontAwesome';
            content: "\f133";
            padding-right: 10px;
            font-size: 72px;
            line-height: 72px;
            text-align: left;
            float: left;
            color: #ebeeee;
        }
    }

    h4 {
        font: 16px/24px 'opensans-bold', sans-serif;
        color: #EBEEEE;
        margin-bottom: 6px;
    }

    p.lead {
        font: 26px/36px 'opensans-light', sans-serif;
        padding-right: 3%;
        color: whitesmoke;
    }

    .header-col {
        padding-top: 6px;
    }

    .contact-details {
        color: whitesmoke;

        h2 {
            color: whitesmoke;
        }

        @media only screen and (max-width: 767px) {
            text-align: center;
        }

    }
}


footer {
    padding-top: 48px;
    margin-bottom: 48px;
    color: #303030;
    font-size: 14px;
    text-align: center;
    position: relative;
 }
 
 footer a, footer a:visited { color: #525252; }
 footer a:hover, footer a:focus { color: #fff; }
 
 
 /* social links */
 footer .social-links {
    margin: 18px 0 30px 0;
    padding: 0;
    font-size: 30px;
 }
 footer .social-links li {
     display: inline-block;
     margin: 0;
     padding: 0;
     margin-left: 42px;
     color: #F06000;
 }
 
 footer .social-links li:first-child { margin-left: 0; }
 
 /* Go To Top Button */
 #go-top {
     position: absolute;
     top: -24px;
    left: 50%;
    margin-left: -30px;
 }
 #go-top a {
     text-decoration: none;
     border: 0 none;
     display: block;
     width: 60px;
     height: 60px;
     background-color: #525252;
 
     -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
 
    color: #fff;
    font-size: 21px;
    line-height: 60px;
      border-radius: 100%;
 }
 #go-top a:hover { background-color: #0F9095; }