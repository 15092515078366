@charset "UTF-8";
/*	Blockquotes */
#testimonials {
  background: #1F1F1F url(/images/testimonials-bg2.jpg) no-repeat center center;
  background-size: cover !important;
  -webkit-background-size: cover !important;
  background-attachment: fixed;
  position: relative;
  min-height: 200px;
  width: 100%;
  overflow: hidden;
}
#testimonials .text-container {
  padding-top: 96px;
  padding-bottom: 66px;
}
#testimonials h1 {
  font: 18px/24px "opensans-bold", sans-serif;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #fff;
}
#testimonials h1 span {
  display: none;
}
#testimonials .header-col {
  padding-top: 9px;
}
#testimonials .header-col h1:before {
  font-family: "FontAwesome";
  content: "\f10d";
  padding-right: 10px;
  font-size: 72px;
  line-height: 72px;
  text-align: left;
  float: left;
  color: #fff;
}
#testimonials blockquote {
  margin: 0 0px 30px 0px;
  padding-left: 0;
  position: relative;
  text-shadow: 0px 1px 3px rgb(0, 0, 0);
}
#testimonials blockquote:before {
  content: none;
}
#testimonials blockquote p {
  font-family: "librebaskerville-italic", serif;
  padding: 0;
  font-size: 24px;
  line-height: 48px;
  color: #fff;
}
#testimonials blockquote cite {
  display: block;
  font-size: 12px;
  font-style: normal;
  line-height: 18px;
  color: #fff;
}
#testimonials blockquote cite:before {
  content: "— ";
}
#testimonials blockquote cite a {
  color: #8B9798;
  border: none;
}
#testimonials blockquote cite a:visited {
  color: #8B9798;
  border: none;
}