
#experience {
	background: #fff;
	padding-top: 90px;
	padding-bottom: 72px;
	overflow: hidden;

	a {
		color: #11ABB0;
		&:visited {color: #11ABB0;}
		&:hover {color: #313131;}
		&:focus {color: #313131;}
	}

	h1 {
		font: 18px/24px 'opensans-bold', sans-serif;
		text-transform: uppercase;
		letter-spacing: 1px;
		span {
			border-bottom: 3px solid #11ABB0;
			padding-bottom: 6px;
		}
	}

	h3 {font: 25px/30px 'opensans-bold', sans-serif;
    }
	.header-col {padding-top: 9px;}

	img.logo {
		height: 30px;
		vertical-align: middle;
		margin-right: 10px;
	}

	.info {
		font: 16px/24px 'librebaskerville-italic', serif;
		color: #6E7881;
		margin-bottom: 5px;
		margin-top: 9px;
		
        span {
			margin-right: 5px;
			margin-left: 5px;
		}
	}

	.date {
		font: 15px/24px 'opensans-regular', sans-serif;
		margin-top: 6px;
	}

	ul {list-style: disc outside;}
}


.perspective{
    perspective: 800px;
}

.timelineContainer{
	.header-col{
		transition: transform .25s linear;
		@media only screen and (max-width: 1150px) and (min-width: 768px){
			// margin-right: -10px;
			transform: rotate(-90deg) translateY(4vw);
		}

	}
}

.work, .education  {
	margin-bottom: 48px;
	padding-bottom: 24px;
	border-bottom: 1px solid #E8E8E8;


    .workItem, .educationItem {
        background: linear-gradient( #eee, #fff);
        padding: 30px;
        margin-bottom: 15px;
        border-radius: 15px;
        box-shadow: 0px -10px 6px -5px rgba(0, 0, 0, .2);
        opacity: 0;
        will-change: transform;
		transition: transform .5s;

        &.anim{
            animation: animate-resume 1s;
            opacity: 1;
        }

		&.active{
			transform: scale(1.07);
		}
    }
}



@keyframes animate-resume {
	0% {
		opacity: 0;
		transform: translateX(150px) rotateY(90deg);
	}
	100% {
		opacity: 1;
		transform: translateY(0) rotateY(0deg);
	}
}

/*----------------------------------------------*/
/*	Skill Bars
/*----------------------------------------------*/
@keyframes animate-bar {
	0% {
		width: 100%;
	}
	100% {
		width: var(--barWidth);
	}
}
.skillMessage {
	margin: 15px 0 30px;
	font-size: 18px;
}
.skillsHolder {
	clear: both;
	display: grid;
	margin: 20px 20px 0;
	grid-template-columns: repeat(auto-fill, minmax(max(275px, 100%/3), 1fr));
	row-gap: 20px;
	column-gap: 20px;
}
.short {
	width: 0;
}
.bars {
	width: 95%;
	float: left;
	padding: 0;
	text-align: left;
	transition: width 5s;
	.skills {
		margin-top: 36px;
		list-style: none;
	}
	li {
		position: relative;
		margin-bottom: 60px;
		background: linear-gradient(90deg, #62ACA5, #9DDEDA);
		height: 20px;
		border-radius: 5px;
		list-style: none;
		em {
			font: 15px 'opensans-bold', sans-serif;
			color: #313131;
			text-transform: uppercase;
			letter-spacing: 2px;
			font-weight: normal;
			position: relative;
			top: -36px;
			img {
				display: inline;
				height: 24px;
				margin: -4px 6px -4px 0;
			}
		}
	}
	h3 {
		margin-bottom: 55px;
	}
}
.bar-expand {
	position: absolute;
	right: 0;
	top: 0;
	background-color: #ccc;
	margin: 0;
	padding-right: 24px;
	display: inline-block;
	height: 20px;
	line-height: 20px;
	border-radius: 0 5px 5px 0;
}
.anim {
	.bar-expand {
		animation: animate-bar 3.5s;
	}
}

/* ------------------------------------------------------------------ */
/* e. Portfolio Section
/* ------------------------------------------------------------------ */

#portfolio {
	background: #ebeeee;
	padding-top: 90px;
	padding-bottom: 60px;
	h1 {
		font: 20px/24px 'opensans-semibold', sans-serif;
		text-transform: uppercase;
		letter-spacing: 1px;
		text-align: center;
		margin-bottom: 48px;
		color: #95A3A3;
	}
}

#portfolio-wrapper {
	.columns {
		margin-bottom: 36px;
	}
	justify-content: center;
}



.portfolio-item {
	perspective: 650px;
	float: left;
	position: relative;
	z-index: var(--zIndex);
	.item-wrap {
		background: #fff;
		overflow: hidden;
		position: relative;
		margin: -80px min(60px, 10vw) 0;
		border-radius: 12px;
		box-shadow: -10px 10px 10px rgba(0, 0, 0, .4);
		transform: rotateX(20deg);
		-webkit-transition: all 0.3s ease-in-out .1s;
		-moz-transition: all 0.3s ease-in-out .1s;
		-o-transition: all 0.3s ease-in-out .1s;
		-ms-transition: all 0.3s ease-in-out .1s;
		transition: all 0.3s ease-in-out .1s;

		a {
			display: inline-block;
			cursor: pointer;
			font-size: 30px;
			margin: 0 20px;
			color: #2ff;
			&:hover {color: #0aa;}
			&:active {color: #055;}
		}

		.overlay {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
			-moz-opacity: 0;
			filter: alpha(opacity=0);
			-webkit-transition: opacity 0.3s ease-in-out;
			-moz-transition: opacity 0.3s ease-in-out;
			-o-transition: opacity 0.3s ease-in-out;
			transition: opacity 0.3s ease-in-out;
			background: rgba(0, 0, 0, .4);
		}

		.link-icon {
			display: block;
			color: #fff;
			height: 30px;
			width: 100%;
			font-size: 18px;
			line-height: 30px;
			text-align: center;
			opacity: 0;
			-moz-opacity: 0;
			filter: alpha(opacity=0);
			-webkit-transition: opacity 0.3s ease-in-out;
			-moz-transition: opacity 0.3s ease-in-out;
			-o-transition: opacity 0.3s ease-in-out;
			transition: opacity 0.3s ease-in-out;
			position: absolute;
			top: 70%;
		}

		img {vertical-align: bottom;}
	}

	.portfolio-item-meta {
		padding: 18px;
		h5 {
			font: 20px/28px 'opensans-bold', sans-serif;
			margin-bottom: 10px;
			color: #fff;
		}
		p {
			font: 16px/24px 'opensans', sans-serif;
			color: whitesmoke;
			margin-bottom: 0;
		}
	}

	&:hover {
		.overlay {
			opacity: 1;
			-moz-opacity: 1;
			filter: alpha(opacity=100);
		}
		.link-icon {
			opacity: 1;
			-moz-opacity: 1;
			filter: alpha(opacity=100);
		}
	}
}
@media only screen and (max-width: 767px) {
	#portfolio h1 {margin-bottom: 80px;}

	.portfolio-item {
		.item-wrap {
			&:hover {
				transform: rotateY(0deg) translateY(-80px);
				margin: 100px min(60px, 10vw) 0;
				transition: all 0.3s ease-in-out;
			}
			&:focus {
				transform: rotateY(0deg) translateY(-80px);
				margin: 100px min(60px, 10vw) 0;
				transition: all 0.3s ease-in-out;
			}
			&:active {
				transform: rotateY(0deg) translateY(-80px);
				margin: 100px min(60px, 10vw) 0;
				transition: all 0.3s ease-in-out;
			}
		}
	}
}

@media only screen and (min-width: 768px) {
	#portfolio-wrapper {display: flex;}

	.portfolio-item {
		float: none;
		z-index: 0;
		.item-wrap {
			transform: rotateY(50deg);
			margin: 0 -65px;
			width: max(230px, min(100vw/5, 400px));
			&:hover {
				transform: rotateY(0deg) translateX(-10px);
				margin: 0 50px;
				transition: all 0.3s ease-in-out;
			}
		}
		.portfolio-item-meta p {font-size: 16px !important;}
	}
}
