.bg {
  width: 100%;
  margin: auto;
  height: 100vh;
  max-height: 600px;
  overflow: hidden;
  position: absolute;
  top: 0;
}
.bg .aur_cont {
  margin: -65px auto 0;
  display: flex;
  justify-content: space-evenly;
  height: auto;
  max-width: 1440px;
}
.bg .aur_cont .aur {
  -webkit-transform: skew(-0.06turn, 18deg);
          transform: skew(-0.06turn, 18deg);
  display: block;
  width: 0;
  min-height: 200px;
  float: left;
  margin-left: 40px;
  border-radius: 5% 52% 30px 20px;
  opacity: 0.8;
}
.bg .aur_cont .aur.aur_1 {
  box-shadow: #4b9daf 0px 0px 100px 20px;
  margin-top: 62px;
  -webkit-animation: topup 4474ms infinite linear;
          animation: topup 4474ms infinite linear;
}
.bg .aur_cont .aur.aur_2 {
  box-shadow: #4b8a48 0px 0px 100px 20px;
  margin-top: 61px;
  -webkit-animation: topup 5678ms infinite linear;
          animation: topup 5678ms infinite linear;
}
.bg .aur_cont .aur.aur_3 {
  box-shadow: #4bcfc1 0px 0px 100px 20px;
  margin-top: -16px;
  -webkit-animation: topup 5527ms infinite linear;
          animation: topup 5527ms infinite linear;
}
.bg .aur_cont .aur.aur_4 {
  box-shadow: #4b8ccb 0px 0px 100px 20px;
  margin-top: 98px;
  -webkit-animation: topup 5667ms infinite linear;
          animation: topup 5667ms infinite linear;
}
.bg .aur_cont .aur.aur_5 {
  box-shadow: #4bffd4 0px 0px 100px 20px;
  margin-top: 84px;
  -webkit-animation: topup 6413ms infinite linear;
          animation: topup 6413ms infinite linear;
}
.bg .aur_cont .aur.aur_6 {
  box-shadow: #4b6d63 0px 0px 100px 20px;
  margin-top: 46px;
  -webkit-animation: topup 3258ms infinite linear;
          animation: topup 3258ms infinite linear;
}
.bg .aur_cont .aur.aur_7 {
  box-shadow: #4bff59 0px 0px 100px 20px;
  margin-top: -21px;
  -webkit-animation: topup 4990ms infinite linear;
          animation: topup 4990ms infinite linear;
}
.bg .aur_cont .aur.aur_8 {
  box-shadow: #4bff8f 0px 0px 100px 20px;
  margin-top: -22px;
  -webkit-animation: topup 3977ms infinite linear;
          animation: topup 3977ms infinite linear;
}
.bg .aur_cont .aur.aur_9 {
  box-shadow: #4bcabe 0px 0px 100px 20px;
  margin-top: 40px;
  -webkit-animation: topup 5328ms infinite linear;
          animation: topup 5328ms infinite linear;
}
.bg .aur_cont .aur.aur_10 {
  box-shadow: #4bb7e8 0px 0px 100px 20px;
  margin-top: 13px;
  -webkit-animation: topup 7865ms infinite linear;
          animation: topup 7865ms infinite linear;
}
.bg .aur_cont .aur.aur_11 {
  box-shadow: #4b76ff 0px 0px 100px 20px;
  margin-top: 57px;
  -webkit-animation: topup 4700ms infinite linear;
          animation: topup 4700ms infinite linear;
}
.bg .aur_cont .aur.aur_12 {
  box-shadow: #4bffff 0px 0px 100px 20px;
  margin-top: 48px;
  -webkit-animation: topup 3096ms infinite linear;
          animation: topup 3096ms infinite linear;
}
.bg .aur_cont .aur.aur_13 {
  box-shadow: #4ba04f 0px 0px 100px 20px;
  margin-top: 90px;
  -webkit-animation: topup 7410ms infinite linear;
          animation: topup 7410ms infinite linear;
}
.bg .aur_cont .aur.aur_14 {
  box-shadow: #4bc1df 0px 0px 100px 20px;
  margin-top: 90px;
  -webkit-animation: topup 3806ms infinite linear;
          animation: topup 3806ms infinite linear;
}
.bg .aur_cont .aur.aur_15 {
  box-shadow: #4bff43 0px 0px 100px 20px;
  margin-top: 79px;
  -webkit-animation: topup 5203ms infinite linear;
          animation: topup 5203ms infinite linear;
}
.bg .aur_cont .aur.aur_16 {
  box-shadow: #4b8941 0px 0px 100px 20px;
  margin-top: 6px;
  -webkit-animation: topup 4291ms infinite linear;
          animation: topup 4291ms infinite linear;
}
.bg .aur_cont .aur.aur_17 {
  box-shadow: #4bffba 0px 0px 100px 20px;
  margin-top: 11px;
  -webkit-animation: topup 4189ms infinite linear;
          animation: topup 4189ms infinite linear;
}
.bg .aur_cont .aur.aur_18 {
  box-shadow: #4b91a0 0px 0px 100px 20px;
  margin-top: 3px;
  -webkit-animation: topup 4505ms infinite linear;
          animation: topup 4505ms infinite linear;
}
.bg .aur_cont .aur.aur_19 {
  box-shadow: #4bbfff 0px 0px 100px 20px;
  margin-top: 19px;
  -webkit-animation: topup 4422ms infinite linear;
          animation: topup 4422ms infinite linear;
}
.bg .aur_cont .aur.aur_20 {
  box-shadow: #4b71ff 0px 0px 100px 20px;
  margin-top: 89px;
  -webkit-animation: topup 5803ms infinite linear;
          animation: topup 5803ms infinite linear;
}
.bg .aur_cont .aur.aur_21 {
  box-shadow: #4bffff 0px 0px 100px 20px;
  margin-top: -22px;
  -webkit-animation: topup 5319ms infinite linear;
          animation: topup 5319ms infinite linear;
}
.bg .aur_cont .aur.aur_22 {
  box-shadow: #4bff77 0px 0px 100px 20px;
  margin-top: -34px;
  -webkit-animation: topup 3916ms infinite linear;
          animation: topup 3916ms infinite linear;
}
.bg .aur_cont .aur.aur_23 {
  box-shadow: #4bffc5 0px 0px 100px 20px;
  margin-top: 1px;
  -webkit-animation: topup 5464ms infinite linear;
          animation: topup 5464ms infinite linear;
}
.bg .aur_cont .aur.aur_24 {
  box-shadow: #4bc1ff 0px 0px 100px 20px;
  margin-top: 51px;
  -webkit-animation: topup 7334ms infinite linear;
          animation: topup 7334ms infinite linear;
}

@-webkit-keyframes topup {
  0%, 100% {
    -webkit-transform: translatey(30px) rotate(20deg);
            transform: translatey(30px) rotate(20deg);
  }
  25% {
    -webkit-transform: translatey(100px) rotate(10deg) translatex(30px);
            transform: translatey(100px) rotate(10deg) translatex(30px);
  }
  50% {
    -webkit-transform: translatey(200px) rotate(-10deg);
            transform: translatey(200px) rotate(-10deg);
  }
  75% {
    -webkit-transform: translatey(100px) rotate(10deg) translatex(-30px);
            transform: translatey(100px) rotate(10deg) translatex(-30px);
  }
}

@keyframes topup {
  0%, 100% {
    -webkit-transform: translatey(30px) rotate(20deg);
            transform: translatey(30px) rotate(20deg);
  }
  25% {
    -webkit-transform: translatey(100px) rotate(10deg) translatex(30px);
            transform: translatey(100px) rotate(10deg) translatex(30px);
  }
  50% {
    -webkit-transform: translatey(200px) rotate(-10deg);
            transform: translatey(200px) rotate(-10deg);
  }
  75% {
    -webkit-transform: translatey(100px) rotate(10deg) translatex(-30px);
            transform: translatey(100px) rotate(10deg) translatex(-30px);
  }
}
header {
  position: relative;
  height: 800px;
  min-height: 500px;
  width: 100%;
  background: #161415 url(/images/header-background.jpg) no-repeat top center;
  background-size: cover !important;
  -webkit-background-size: cover !important;
  text-align: center;
  overflow: hidden;
}
header:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}
header .banner {
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  width: 85%;
  padding-bottom: 30px;
  text-align: center;
  position: relative;
  z-index: 1;
}
header .banner-text {
  width: 100%;
}
header .banner-text h1 {
  color: #fff;
  letter-spacing: -2px;
  font: 90px/1.1em "opensans-bold", sans-serif;
  margin: 0 auto 18px auto;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.8);
}
header .banner-text h3 {
  font: 18px/1.9em "librebaskerville-regular", serif;
  color: #A8A8A8;
  margin: 0 auto;
  width: 70%;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
}
header .banner-text h3 span, header .banner-text h3 a {
  color: #fff;
}
header .banner-text hr {
  width: 60%;
  margin: 18px auto 24px auto;
  border-color: #2F2D2E;
  border-color: rgba(150, 150, 150, 0.1);
}
header .social {
  margin: 24px 0;
  padding: 0;
  font-size: 42px;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.8);
}
header .social li {
  display: inline-block;
  margin: 0 25px;
  padding: 0;
}
header .social li a {
  color: #fff;
}
header .social li a:hover {
  color: #11ABB0;
}
header .scrolldown a {
  position: absolute;
  bottom: 30px;
  left: 50%;
  margin-left: -29px;
  color: #fff;
  display: block;
  height: 42px;
  width: 42px;
  font-size: 42px;
  line-height: 42px;
  color: #fff;
  border-radius: 100%;
  transition: all 0.3s ease-in-out;
}
header .scrolldown a:hover {
  color: #11ABB0;
}

.banner-desc {
  -webkit-animation: fade-up 1.5s;
          animation: fade-up 1.5s;
}

#nav-wrap {
  font: 12px "opensans-bold", sans-serif;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  margin: 0 auto;
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
}
#nav-wrap ul {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
}
#nav-wrap li {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
}
#nav-wrap a {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
}
#nav-wrap > a.mobile-btn {
  display: none;
}

.opaque {
  background-color: #333;
}

ul#nav {
  min-height: 48px;
  width: auto;
  text-align: center;
}
ul#nav li {
  position: relative;
  list-style: none;
  height: 48px;
  display: inline-block;
}
ul#nav li a {
  display: inline-block;
  padding: 8px 13px;
  line-height: 32px;
  text-decoration: none;
  text-align: left;
  color: #fff;
  transition: color 0.2s ease-in-out;
}
ul#nav li a:active {
  background-color: transparent !important;
}
ul#nav li.current a {
  color: #F06000;
}

@-webkit-keyframes fade-up {
  from {
    opacity: 0;
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes fade-up {
  from {
    opacity: 0;
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
#resume {
  background: #2B2B2B;
  padding-top: 96px;
  padding-bottom: 66px;
  overflow: hidden;
}
#resume a {
  color: #fff;
}
#resume a:visited {
  color: #fff;
}
#resume a:hover {
  color: #11ABB0;
}
#resume a:focus {
  color: #11ABB0;
}
#resume h2 {
  font: 22px/30px "opensans-bold", sans-serif;
  color: #fff;
  margin-bottom: 12px;
}
#resume p {
  line-height: 30px;
  color: #7A7A7A;
}
#resume .profile-pic {
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 100%;
}
#resume .contact-details {
  width: 41.66667%;
}
#resume .download {
  width: 58.33333%;
  padding-top: 6px;
}
#resume .download .button {
  margin-top: 6px;
  background: #444;
}
#resume .download .button:hover {
  background: #fff;
  color: #2B2B2B;
}
#resume .download .button i {
  margin-right: 15px;
  font-size: 20px;
}
#resume .main-col {
  padding-right: 5%;
}
.timelineContainer {
  position: relative;
}

.timeline {
  position: absolute;
  display: flex;
  top: 0;
  bottom: 30px;
  margin-left: 36px;
}
@media only screen and (max-width: 540px) {
  .timeline {
    display: none;
  }
}
.timeline .line {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 11;
}
.timeline .line .yearDotContainer {
  display: flex;
  align-items: center;
}
.timeline .line .year {
  position: absolute;
  left: -40px;
  font-size: 12px;
  color: black;
  font-family: Trebuchet MS, sans-serif;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  -webkit-transform-origin: center 0;
          transform-origin: center 0;
}
.timeline .line .yearDot, .timeline .line .halfYearDot {
  border: 1px solid black;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 10px;
  background-color: white;
}
.timeline .line .first .yearDot {
  background-color: teal;
}
.timeline .line .last .yearDot {
  background-color: #CF756C;
  border-radius: 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.timeline .line .halfYearDot {
  height: 6px;
  width: 6px;
}
.timeline .line .connectLine {
  border: 1px solid black;
  width: 0;
  flex: 1 1;
}
.timeline .items {
  height: 100%;
  position: relative;
  margin-left: -4px;
}
.timeline .items .item {
  position: absolute;
  white-space: nowrap;
  border-left: 12px solid;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  z-index: 10;
  transition: -webkit-filter 0.25s;
  transition: filter 0.25s;
  transition: filter 0.25s, -webkit-filter 0.25s;
}
.timeline .items .item:hover {
  -webkit-filter: brightness(80%);
          filter: brightness(80%);
}
#experience {
  background: #fff;
  padding-top: 90px;
  padding-bottom: 72px;
  overflow: hidden;
}
#experience a {
  color: #11ABB0;
}
#experience a:visited {
  color: #11ABB0;
}
#experience a:hover {
  color: #313131;
}
#experience a:focus {
  color: #313131;
}
#experience h1 {
  font: 18px/24px "opensans-bold", sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
}
#experience h1 span {
  border-bottom: 3px solid #11ABB0;
  padding-bottom: 6px;
}
#experience h3 {
  font: 25px/30px "opensans-bold", sans-serif;
}
#experience .header-col {
  padding-top: 9px;
}
#experience img.logo {
  height: 30px;
  vertical-align: middle;
  margin-right: 10px;
}
#experience .info {
  font: 16px/24px "librebaskerville-italic", serif;
  color: #6E7881;
  margin-bottom: 5px;
  margin-top: 9px;
}
#experience .info span {
  margin-right: 5px;
  margin-left: 5px;
}
#experience .date {
  font: 15px/24px "opensans-regular", sans-serif;
  margin-top: 6px;
}
#experience ul {
  list-style: disc outside;
}

.perspective {
  -webkit-perspective: 800px;
          perspective: 800px;
}

.timelineContainer .header-col {
  transition: -webkit-transform 0.25s linear;
  transition: transform 0.25s linear;
  transition: transform 0.25s linear, -webkit-transform 0.25s linear;
}
@media only screen and (max-width: 1150px) and (min-width: 768px) {
  .timelineContainer .header-col {
    -webkit-transform: rotate(-90deg) translateY(4vw);
            transform: rotate(-90deg) translateY(4vw);
  }
}

.work, .education {
  margin-bottom: 48px;
  padding-bottom: 24px;
  border-bottom: 1px solid #E8E8E8;
}
.work .workItem, .work .educationItem, .education .workItem, .education .educationItem {
  background: linear-gradient(#eee, #fff);
  padding: 30px;
  margin-bottom: 15px;
  border-radius: 15px;
  box-shadow: 0px -10px 6px -5px rgba(0, 0, 0, 0.2);
  opacity: 0;
  will-change: transform;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}
.work .workItem.anim, .work .educationItem.anim, .education .workItem.anim, .education .educationItem.anim {
  -webkit-animation: animate-resume 1s;
          animation: animate-resume 1s;
  opacity: 1;
}
.work .workItem.active, .work .educationItem.active, .education .workItem.active, .education .educationItem.active {
  -webkit-transform: scale(1.07);
          transform: scale(1.07);
}

@-webkit-keyframes animate-resume {
  0% {
    opacity: 0;
    -webkit-transform: translateX(150px) rotateY(90deg);
            transform: translateX(150px) rotateY(90deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0) rotateY(0deg);
            transform: translateY(0) rotateY(0deg);
  }
}

@keyframes animate-resume {
  0% {
    opacity: 0;
    -webkit-transform: translateX(150px) rotateY(90deg);
            transform: translateX(150px) rotateY(90deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0) rotateY(0deg);
            transform: translateY(0) rotateY(0deg);
  }
}
/*----------------------------------------------*/
/*	Skill Bars
/*----------------------------------------------*/
@-webkit-keyframes animate-bar {
  0% {
    width: 100%;
  }
  100% {
    width: var(--barWidth);
  }
}
@keyframes animate-bar {
  0% {
    width: 100%;
  }
  100% {
    width: var(--barWidth);
  }
}
.skillMessage {
  margin: 15px 0 30px;
  font-size: 18px;
}

.skillsHolder {
  clear: both;
  display: grid;
  margin: 20px 20px 0;
  grid-template-columns: repeat(auto-fill, minmax(max(275px, 33.3333333333%), 1fr));
  grid-row-gap: 20px;
  row-gap: 20px;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.short {
  width: 0;
}

.bars {
  width: 95%;
  float: left;
  padding: 0;
  text-align: left;
  transition: width 5s;
}
.bars .skills {
  margin-top: 36px;
  list-style: none;
}
.bars li {
  position: relative;
  margin-bottom: 60px;
  background: linear-gradient(90deg, #62ACA5, #9DDEDA);
  height: 20px;
  border-radius: 5px;
  list-style: none;
}
.bars li em {
  font: 15px "opensans-bold", sans-serif;
  color: #313131;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: normal;
  position: relative;
  top: -36px;
}
.bars li em img {
  display: inline;
  height: 24px;
  margin: -4px 6px -4px 0;
}
.bars h3 {
  margin-bottom: 55px;
}

.bar-expand {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #ccc;
  margin: 0;
  padding-right: 24px;
  display: inline-block;
  height: 20px;
  line-height: 20px;
  border-radius: 0 5px 5px 0;
}

.anim .bar-expand {
  -webkit-animation: animate-bar 3.5s;
          animation: animate-bar 3.5s;
}

/* ------------------------------------------------------------------ */
/* e. Portfolio Section
/* ------------------------------------------------------------------ */
#portfolio {
  background: #ebeeee;
  padding-top: 90px;
  padding-bottom: 60px;
}
#portfolio h1 {
  font: 20px/24px "opensans-semibold", sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  margin-bottom: 48px;
  color: #95A3A3;
}

#portfolio-wrapper {
  justify-content: center;
}
#portfolio-wrapper .columns {
  margin-bottom: 36px;
}

.portfolio-item {
  -webkit-perspective: 650px;
          perspective: 650px;
  float: left;
  position: relative;
  z-index: var(--zIndex);
}
.portfolio-item .item-wrap {
  background: #fff;
  overflow: hidden;
  position: relative;
  margin: -80px min(60px, 10vw) 0;
  border-radius: 12px;
  box-shadow: -10px 10px 10px rgba(0, 0, 0, 0.4);
  -webkit-transform: rotateX(20deg);
          transform: rotateX(20deg);
  transition: all 0.3s ease-in-out 0.1s;
}
.portfolio-item .item-wrap a {
  display: inline-block;
  cursor: pointer;
  font-size: 30px;
  margin: 0 20px;
  color: #2ff;
}
.portfolio-item .item-wrap a:hover {
  color: #0aa;
}
.portfolio-item .item-wrap a:active {
  color: #055;
}
.portfolio-item .item-wrap .overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  -moz-opacity: 0;
  filter: alpha(opacity=0);
  transition: opacity 0.3s ease-in-out;
  background: rgba(0, 0, 0, 0.4);
}
.portfolio-item .item-wrap .link-icon {
  display: block;
  color: #fff;
  height: 30px;
  width: 100%;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  opacity: 0;
  -moz-opacity: 0;
  filter: alpha(opacity=0);
  transition: opacity 0.3s ease-in-out;
  position: absolute;
  top: 70%;
}
.portfolio-item .item-wrap img {
  vertical-align: bottom;
}
.portfolio-item .portfolio-item-meta {
  padding: 18px;
}
.portfolio-item .portfolio-item-meta h5 {
  font: 20px/28px "opensans-bold", sans-serif;
  margin-bottom: 10px;
  color: #fff;
}
.portfolio-item .portfolio-item-meta p {
  font: 16px/24px "opensans", sans-serif;
  color: whitesmoke;
  margin-bottom: 0;
}
.portfolio-item:hover .overlay {
  opacity: 1;
  -moz-opacity: 1;
  filter: alpha(opacity=100);
}
.portfolio-item:hover .link-icon {
  opacity: 1;
  -moz-opacity: 1;
  filter: alpha(opacity=100);
}

@media only screen and (max-width: 767px) {
  #portfolio h1 {
    margin-bottom: 80px;
  }
  .portfolio-item .item-wrap:hover {
    -webkit-transform: rotateY(0deg) translateY(-80px);
            transform: rotateY(0deg) translateY(-80px);
    margin: 100px min(60px, 10vw) 0;
    transition: all 0.3s ease-in-out;
  }
  .portfolio-item .item-wrap:focus {
    -webkit-transform: rotateY(0deg) translateY(-80px);
            transform: rotateY(0deg) translateY(-80px);
    margin: 100px min(60px, 10vw) 0;
    transition: all 0.3s ease-in-out;
  }
  .portfolio-item .item-wrap:active {
    -webkit-transform: rotateY(0deg) translateY(-80px);
            transform: rotateY(0deg) translateY(-80px);
    margin: 100px min(60px, 10vw) 0;
    transition: all 0.3s ease-in-out;
  }
}
@media only screen and (min-width: 768px) {
  #portfolio-wrapper {
    display: flex;
  }
  .portfolio-item {
    float: none;
    z-index: 0;
  }
  .portfolio-item .item-wrap {
    -webkit-transform: rotateY(50deg);
            transform: rotateY(50deg);
    margin: 0 -65px;
    width: max(230px, min(20vw, 400px));
  }
  .portfolio-item .item-wrap:hover {
    -webkit-transform: rotateY(0deg) translateX(-10px);
            transform: rotateY(0deg) translateX(-10px);
    margin: 0 50px;
    transition: all 0.3s ease-in-out;
  }
  .portfolio-item .portfolio-item-meta p {
    font-size: 16px !important;
  }
}
#contact {
  background: #191919;
  padding-top: 96px;
  padding-bottom: 102px;
  color: #636363;
}
#contact .iframeContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#contact .iframeContainer .hoverMsg {
  opacity: 0;
  z-index: 2;
  position: absolute;
  pointer-events: none;
  border-radius: 10px;
  padding: 20px;
  width: 500px;
  max-width: 92vw;
  color: white;
  background-color: #444;
  will-change: opacity, transform;
  text-align: center;
}
#contact .iframeContainer .hoverMsg p {
  margin-bottom: 0;
}
#contact .iframeContainer .hoverMsg hr {
  margin-bottom: 15px;
}
@media only screen and (max-width: 500px) {
  #contact .iframeContainer .hoverMsg {
    font-size: 11px;
  }
}
#contact iframe {
  box-sizing: border-box;
  -webkit-filter: invert(0.9) saturate(0.5) hue-rotate(145deg);
          filter: invert(0.9) saturate(0.5) hue-rotate(145deg);
  display: block;
  margin: 0 auto 30px;
  width: 92vw;
  max-width: 1024px;
}
#contact iframe.showMsg + .hoverMsg {
  -webkit-animation: showMessage 5s;
          animation: showMessage 5s;
}
@-webkit-keyframes showMessage {
  0% {
    opacity: 0;
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
  }
  20% {
    opacity: 0.9;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  80% {
    opacity: 0.9;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
  }
}
@keyframes showMessage {
  0% {
    opacity: 0;
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
  }
  20% {
    opacity: 0.9;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  80% {
    opacity: 0.9;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
  }
}
#contact .section-head {
  margin-bottom: 42px;
}
#contact h1 {
  font: 18px/24px "opensans-bold", sans-serif;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #EBEEEE;
  margin-bottom: 6px;
}
#contact h1 span {
  display: none;
}
#contact h1:before {
  font-family: "FontAwesome";
  content: "\F133";
  padding-right: 10px;
  font-size: 72px;
  line-height: 72px;
  text-align: left;
  float: left;
  color: #ebeeee;
}
#contact h4 {
  font: 16px/24px "opensans-bold", sans-serif;
  color: #EBEEEE;
  margin-bottom: 6px;
}
#contact p.lead {
  font: 26px/36px "opensans-light", sans-serif;
  padding-right: 3%;
  color: whitesmoke;
}
#contact .header-col {
  padding-top: 6px;
}
#contact .contact-details {
  color: whitesmoke;
}
#contact .contact-details h2 {
  color: whitesmoke;
}
@media only screen and (max-width: 767px) {
  #contact .contact-details {
    text-align: center;
  }
}

footer {
  padding-top: 48px;
  margin-bottom: 48px;
  color: #303030;
  font-size: 14px;
  text-align: center;
  position: relative;
}

footer a, footer a:visited {
  color: #525252;
}

footer a:hover, footer a:focus {
  color: #fff;
}

/* social links */
footer .social-links {
  margin: 18px 0 30px 0;
  padding: 0;
  font-size: 30px;
}

footer .social-links li {
  display: inline-block;
  margin: 0;
  padding: 0;
  margin-left: 42px;
  color: #F06000;
}

footer .social-links li:first-child {
  margin-left: 0;
}

/* Go To Top Button */
#go-top {
  position: absolute;
  top: -24px;
  left: 50%;
  margin-left: -30px;
}

#go-top a {
  text-decoration: none;
  border: 0 none;
  display: block;
  width: 60px;
  height: 60px;
  background-color: #525252;
  transition: all 0.2s ease-in-out;
  color: #fff;
  font-size: 21px;
  line-height: 60px;
  border-radius: 100%;
}

#go-top a:hover {
  background-color: #0F9095;
}
@charset "UTF-8";
/*	Blockquotes */
#testimonials {
  background: #1F1F1F url(/images/testimonials-bg2.jpg) no-repeat center center;
  background-size: cover !important;
  -webkit-background-size: cover !important;
  background-attachment: fixed;
  position: relative;
  min-height: 200px;
  width: 100%;
  overflow: hidden;
}
#testimonials .text-container {
  padding-top: 96px;
  padding-bottom: 66px;
}
#testimonials h1 {
  font: 18px/24px "opensans-bold", sans-serif;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #fff;
}
#testimonials h1 span {
  display: none;
}
#testimonials .header-col {
  padding-top: 9px;
}
#testimonials .header-col h1:before {
  font-family: "FontAwesome";
  content: "\F10D";
  padding-right: 10px;
  font-size: 72px;
  line-height: 72px;
  text-align: left;
  float: left;
  color: #fff;
}
#testimonials blockquote {
  margin: 0 0px 30px 0px;
  padding-left: 0;
  position: relative;
  text-shadow: 0px 1px 3px rgb(0, 0, 0);
}
#testimonials blockquote:before {
  content: none;
}
#testimonials blockquote p {
  font-family: "librebaskerville-italic", serif;
  padding: 0;
  font-size: 24px;
  line-height: 48px;
  color: #fff;
}
#testimonials blockquote cite {
  display: block;
  font-size: 12px;
  font-style: normal;
  line-height: 18px;
  color: #fff;
}
#testimonials blockquote cite:before {
  content: "\2014   ";
}
#testimonials blockquote cite a {
  color: #8B9798;
  border: none;
}
#testimonials blockquote cite a:visited {
  color: #8B9798;
  border: none;
}
/* ==================================================================

*   Ceevee Media Queries
*   url: styleshout.com
*   03-18-2014

/* ================================================================== */
/* screenwidth less than 1024px
--------------------------------------------------------------------- */
@media only screen and (max-width: 1024px) {
  /* header styles
  ------------------------------------------------------------------ */
  header .banner-text h1 {
    font: 80px/1.1em "opensans-bold", sans-serif;
    letter-spacing: -1px;
    margin: 0 auto 12px auto;
  }
}
/* screenwidth less than 900px
--------------------------------------------------------------------- */
@media only screen and (max-width: 900px) {
  /* header styles
  ------------------------------------------------------------------ */
  header .banner {
    padding-bottom: 12px;
  }
  header .banner-text h1 {
    font: 78px/1.1em "opensans-bold", sans-serif;
    letter-spacing: -1px;
  }
  header .banner-text h3 {
    font: 17px/1.9em "librebaskerville-regular", serif;
    width: 80%;
  }
  header .banner-text hr {
    width: 65%;
    margin: 12px auto;
  }
  /* nav-wrap */
  #nav-wrap {
    font: 11px "opensans-bold", sans-serif;
    letter-spacing: 1.5px;
  }
  /* Resume Section
  ------------------------------------------------------------------- */
  #resume .profile-pic {
    width: 114px;
    height: 114px;
    margin-left: 12px;
  }
  #resume .contact-details, #resume .download {
    width: 50%;
  }
  /* Experience Section
  ------------------------------------------------------------------- */
  #experience h1 {
    font: 16px/24px "opensans-bold", sans-serif;
  }
  #experience .main-col {
    padding-right: 5%;
  }
  /* Testimonials Section
  ------------------------------------------------------------------- */
  #testimonials .header-col h1:before {
    font-size: 66px;
    line-height: 66px;
  }
  #testimonials blockquote p {
    font-size: 22px;
    line-height: 46px;
  }
  /* Call to Action Section
  ------------------------------------------------------------------- */
  #call-to-action .header-col h1:before {
    font-size: 66px;
    line-height: 66px;
  }
  /* Contact Section
  ------------------------------------------------------------------- */
  #contact .section-head {
    margin-bottom: 30px;
  }
  #contact .header-col h1:before {
    font-size: 66px;
    line-height: 66px;
  }
  #contact .section-head p.lead {
    font: 17px/33px opensans-light, sans-serif;
  }
}
/* mobile wide/smaller tablets
---------------------------------------------------------------------- */
@media only screen and (max-width: 767px) {
  /* mobile navigation
  -------------------------------------------------------------------- */
  #nav-wrap {
    font: 12px "opensans-bold", sans-serif;
    background: transparent !important;
    letter-spacing: 1.5px;
    width: auto;
    position: fixed;
    top: 0;
    right: 0;
  }
  #nav-wrap > a {
    width: 48px;
    height: 48px;
    text-align: left;
    background-color: #CC5200;
    position: relative;
    border: none;
    float: right;
    font: 0/0 a;
    text-shadow: none;
    color: transparent;
    position: relative;
    top: 0px;
    right: 30px;
  }
  #nav-wrap > a:before {
    position: absolute;
    border: 2px solid #fff;
    top: 35%;
    left: 25%;
    right: 25%;
    content: "";
  }
  #nav-wrap > a:after {
    position: absolute;
    border: 2px solid #fff;
    top: 35%;
    left: 25%;
    right: 25%;
    content: "";
    top: 60%;
  }
  #nav-wrap:not(:target) > a:first-of-type, #nav-wrap:target > a:last-of-type {
    display: block;
  }
  #nav-wrap ul#nav {
    height: auto;
    display: none;
    clear: both;
    width: auto;
    float: right;
    position: relative;
    top: 12px;
    right: 0;
  }
  #nav-wrap:target > ul#nav {
    display: block;
    padding: 30px 20px 48px 20px;
    background: #1f2024;
    margin: 0 30px;
    clear: both;
  }
  /* toggle buttons */
  /* hide menu panel */
  /* display menu panels */
  ul#nav li {
    display: block;
    height: auto;
    margin: 0 auto;
    padding: 0 4%;
    text-align: left;
    border-bottom: 1px solid #2D2E34;
    border-bottom-style: dotted;
  }
  ul#nav li a {
    display: block;
    margin: 0;
    padding: 0;
    margin: 12px 0;
    line-height: 16px;
    /* reset line-height from 48px */
    border: none;
  }
  /* Header Styles
  -------------------------------------------------------------------- */
  header .banner {
    padding-bottom: 12px;
    padding-top: 6px;
  }
  header .banner-text h1 {
    font: 52px/1.1em "opensans-bold", sans-serif;
  }
  header .banner-text h3 {
    font: 16px/1.9em "librebaskerville-regular", serif;
    width: 85%;
  }
  header .banner-text hr {
    width: 80%;
    margin: 18px auto;
  }
  header .social {
    margin: 18px 0 24px 0;
    font-size: 24px;
    line-height: 36px;
  }
  header .social li {
    margin: 0 10px;
  }
  header .scrolldown {
    display: none;
  }
  /* header social links */
  /* scrolldown link */
  /* About Section
  -------------------------------------------------------------------- */
  #resume .profile-pic {
    margin: 0 auto 20px;
    height: 150px;
    display: block;
    width: 150px;
  }
  #resume .download .button {
    width: 100%;
    text-align: center;
    padding: 15px 20px;
  }
  #resume .main-col {
    padding-right: 30px;
  }
  /* Experience Section
  --------------------------------------------------------------------- */
  #experience .header-col {
    padding-top: 0;
    margin-bottom: 48px;
    text-align: center;
  }
  #experience h1 {
    letter-spacing: 3px;
  }
  #experience .main-col {
    padding-right: 30px;
  }
  #experience h3, #experience .info {
    text-align: center;
  }
  .bars {
    width: 100%;
  }
  /* Call To Action Section
  /* ----------------------------------------------------------------- */
  #call-to-action {
    text-align: center;
  }
  #call-to-action h1 {
    font: 16px/24px "opensans-bold", sans-serif;
    text-align: center;
    margin-bottom: 30px;
    text-shadow: 0px 1px 3px rgb(0, 0, 0);
  }
  #call-to-action h1 span {
    display: block;
  }
  #call-to-action .header-col h1:before {
    content: none;
  }
  #call-to-action p {
    font-size: 15px;
  }
  /* Portfolio Section
  /* ----------------------------------------------------------------- */
  #portfolio-wrapper .columns {
    margin-bottom: 40px;
  }
  .popup-modal {
    max-width: 85%;
  }
  /* Testimonials Section
  ----------------------------------------------------------------------- */
  #testimonials {
    background-attachment: unset !important;
  }
  #testimonials .text-container {
    text-align: center;
  }
  #testimonials h1 {
    font: 16px/24px "opensans-bold", sans-serif;
    text-align: center;
    margin-bottom: 30px;
    text-shadow: 0px 1px 3px rgb(0, 0, 0);
  }
  #testimonials h1 span {
    display: block;
  }
  #testimonials .header-col h1:before {
    content: none;
  }
  #testimonials blockquote {
    padding-bottom: 24px;
  }
  #testimonials blockquote p {
    font-size: 20px;
    line-height: 42px;
  }
  /* Control Nav */
  .flex-control-nav {
    text-align: center;
    margin-left: -30px;
  }
  /* contact Section
  ----------------------------------------------------------------------- */
  #contact {
    padding-bottom: 66px;
  }
  #contact .section-head {
    margin-bottom: 12px;
  }
  #contact .section-head h1 {
    font: 16px/24px "opensans-bold", sans-serif;
    text-align: center;
    margin-bottom: 30px;
    text-shadow: 0px 1px 3px rgb(0, 0, 0);
  }
  #contact h1 span {
    display: block;
  }
  #contact .header-col {
    padding-top: 0;
  }
  #contact .header-col h1:before {
    content: none;
  }
  #contact .section-head p.lead {
    text-align: center;
  }
  #contact label {
    float: none;
    width: 100%;
  }
  #contact input, #contact textarea, #contact select {
    margin-bottom: 6px;
    width: 100%;
  }
  #contact button.submit {
    margin: 30px 0 24px 0;
  }
  /* form */
  #message-warning, #message-success {
    width: 100%;
    margin-left: 0;
  }
  /* footer
  ------------------------------------------------------------------------ */
  /* copyright */
  footer .copyright li {
    margin-right: 12px;
  }
  footer .copyright li:before {
    content: none;
  }
  footer .social-links {
    font-size: 22px;
  }
  footer .social-links li {
    margin-left: 18px;
  }
  /* social links */
  /* Go To Top Button */
  #go-top {
    margin-left: -22px;
  }
  #go-top a {
    width: 54px;
    height: 54px;
    font-size: 18px;
    line-height: 54px;
  }
}
/* mobile narrow
  -------------------------------------------------------------------------- */
@media only screen and (max-width: 480px) {
  /* mobile navigation
  -------------------------------------------------------------------- */
  #nav-wrap ul#nav {
    width: auto;
    float: none;
  }
  /* header styles
  -------------------------------------------------------------------- */
  header .banner {
    padding-top: 24px;
  }
  header .banner-text h1 {
    font: 40px/1.1em "opensans-bold", sans-serif;
    margin: 0 auto 24px auto;
  }
  header .banner-text h3 {
    font: 14px/1.9em "librebaskerville-regular", sans-serif;
    width: 90%;
  }
  header .social {
    font-size: 20px;
  }
  header .social li {
    margin: 0 6px;
  }
  /* header social links */
  /* footer
  ------------------------------------------------------------------------ */
  /* social links */
  footer .social-links {
    font-size: 20px;
  }
  footer .social-links li {
    margin-left: 14px;
  }
}
body {
  background: #0f0f0f;
}
