
header {
	position: relative;
	height: 800px;
	min-height: 500px;
	width: 100%;
	background: #161415 url(/images/header-background.jpg) no-repeat top center;
	background-size: cover !important;
	-webkit-background-size: cover !important;
	text-align: center;
	overflow: hidden;

	&:before {
		content: '';
		display: inline-block;
		vertical-align: middle;
		height: 100%;
	}

	.banner {
		display: inline-block;
		vertical-align: middle;
		margin: 0 auto;
		width: 85%;
		padding-bottom: 30px;
		text-align: center;
		position: relative;
		z-index: 1;
	}

	.banner-text {
		width: 100%;
		h1 {
			color: #fff;
			letter-spacing: -2px;
			font: 90px/1.1em 'opensans-bold', sans-serif;
			margin: 0 auto 18px auto;
			text-shadow: 0px 1px 3px rgba(0, 0, 0, .8);
		}

		h3 {
			font: 18px/1.9em 'librebaskerville-regular', serif;
			color: #A8A8A8;
			margin: 0 auto;
			width: 70%;
			text-shadow: 0px 1px 2px rgba(0, 0, 0, .5);
			
            span, a {color: #fff;}

		}

		hr {
			width: 60%;
			margin: 18px auto 24px auto;
			border-color: #2F2D2E;
			border-color: rgba(150, 150, 150, .1);
		}
	}

	.social {
		margin: 24px 0;
		padding: 0;
		font-size: 42px;
		text-shadow: 0px 1px 2px rgba(0, 0, 0, .8);
		li {
			display: inline-block;
			margin: 0 25px;
			padding: 0;
			
            a {
				color: #fff;
				
                &:hover {color: #11ABB0;}
			}
		}
	}
	.scrolldown {
		a {
			position: absolute;
			bottom: 30px;
			left: 50%;
			margin-left: -29px;
			color: #fff;
			display: block;
			height: 42px;
			width: 42px;
			font-size: 42px;
			line-height: 42px;
			color: #fff;
			border-radius: 100%;
			-webkit-transition: all .3s ease-in-out;
			-moz-transition: all .3s ease-in-out;
			-o-transition: all .3s ease-in-out;
			transition: all .3s ease-in-out;
			&:hover {
				color: #11ABB0;
			}
		}
	}
}
.banner-desc {
	animation: fade-up 1.5s;
}

#nav-wrap {
    font: 12px 'opensans-bold', sans-serif;
	width: 100%;
	text-transform: uppercase;
	letter-spacing: 2.5px;
	margin: 0 auto;
	z-index: 100;
	position: fixed;
	left: 0;
	top: 0;

	ul {
		margin: 0;
		padding: 0;
		border: none;
		outline: none;
	}

	li {
		margin: 0;
		padding: 0;
		border: none;
		outline: none;
	}

	a {
		margin: 0;
		padding: 0;
		border: none;
		outline: none;
	}

	>a.mobile-btn {
		display: none;
	}
}

.opaque {background-color: #333;}

ul#nav {

	min-height: 48px;
	width: auto;
	text-align: center;
    
	li {
		position: relative;
		list-style: none;
		height: 48px;
		display: inline-block;

		a {
			display: inline-block;
			padding: 8px 13px;
			line-height: 32px;
			text-decoration: none;
			text-align: left;
			color: #fff;
			-webkit-transition: color .2s ease-in-out;
			-moz-transition: color .2s ease-in-out;
			-o-transition: color .2s ease-in-out;
			-ms-transition: color .2s ease-in-out;
			transition: color .2s ease-in-out;
			&:active {
				background-color: transparent !important;
			}
		}
	}

	li.current a {color: #F06000;}

}

@keyframes fade-up {
	from {
		opacity: 0;
		transform: translateY(20px);
	}
	to {
		opacity: 1;
		transform: translateY(0px);
	}
}