#resume {
	background: #2B2B2B;
	padding-top: 96px;
	padding-bottom: 66px;
	overflow: hidden;

	a {
		color: #fff;
		&:visited {color: #fff;}
		&:hover {color: #11ABB0;}
		&:focus {color: #11ABB0;}
	}

	h2 {
		font: 22px/30px 'opensans-bold', sans-serif;
		color: #fff;
		margin-bottom: 12px;
	}

	p {
		line-height: 30px;
		color: #7A7A7A;
	}

	.profile-pic {
		position: relative;
		width: 120px;
		height: 120px;
		border-radius: 100%;
	}

	.contact-details {
		width: 41.66667%;
	}

	.download {
		width: 58.33333%;
		padding-top: 6px;
        
		.button {
			margin-top: 6px;
			background: #444;
			&:hover {
				background: #fff;
				color: #2B2B2B;
			}
			i {
				margin-right: 15px;
				font-size: 20px;
			}
		}
	}

	.main-col {
		padding-right: 5%;
	}
}
