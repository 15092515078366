.timelineContainer{
   position: relative;
}
.timeline{
    position: absolute;
    display: flex;
    top: 0;
    bottom: 30px;
    margin-left: 36px;

    @media only screen and (max-width: 540px){
        display: none;
    }

    .line{
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        z-index: 11;

        .yearDotContainer{
            display: flex;
            align-items: center;
        }

        .year{
            position: absolute;
            left: -40px;
            font-size: 12px;
            color: black;
            font-family: Trebuchet MS, sans-serif;
            transform: rotate(-90deg);
            transform-origin: center 0;
        }
        
        .yearDot, .halfYearDot{
            border: 1px solid black;
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 10px;
            background-color: white;

        }

        .first .yearDot{background-color: teal;}
        .last .yearDot{
            background-color: #CF756C;
            border-radius: 0;
            transform: rotate(45deg);
        }
        .halfYearDot{
            height: 6px;
            width: 6px;
        }

        .connectLine{
            border: 1px solid black;
            width: 0;
            flex: 1;
        }
    }

    .items{
        height: 100%;
        position: relative;
        margin-left: -4px;

        .item{
            position: absolute;
            white-space: nowrap;
            // width: 10px;
            border-left: 12px solid ;
            border-top: 12px solid transparent;
            border-bottom: 12px solid transparent;
            z-index: 10;

            transition: filter .25s;


            &:hover{
                filter: brightness(80%);
            }

        }
    }
}